import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage: React.FC = () => (
  <Layout>
    <SEO title="untilnow." />
    <h1 data-testid="index-header">
      until
      <span data-testid="index-accent" className="untilnow__typography__accent">
        now
      </span>
      .
    </h1>
  </Layout>
);

export default IndexPage;
